<valben-header
    [contentType]="contentType"
    [editEnabled]="editEnabled"
    (contentTypeChange)="setContentType($event)">
</valben-header>

<div
    class="relative grow overflow-y-auto"
    [ngSwitch]="contentType"
    [ngClass]="(loading$ | async) ? 'blur-[2px] pointer-events-none [&_*]:select-none' : ''">

    <div *ngIf="loading$ | async" class="absolute flex left-1/2 top-1/2 justify-center items-center z-50">
        <div class="pi pi-spin pi-spinner text-[10rem] text-black/80"></div>
    </div>

    <div *ngSwitchCase="0">
        <valben-table
            (editEvent)="editOnClick($event)"
            [columns]="cols"
            [values]="tableValues"
            [refresh]="refresh">
        </valben-table>
    </div>

    <div *ngSwitchCase="1" class="flex flex-col gap-4 bg-valamargray border-2 static p-4">
        <button class="flex valben-button text-lg ml-auto" (click)="save()">Spremi</button>
        <valben-dictionary-input
            [textArea]="false"
            [key]="createBenefitKeys.Names"
            [itemObservable]="addItemObservable"
            [update]="updateAddNames"
            [currentItem]="currentAddItem"
            title="Naziv"
            [error]="cErrors[createBenefitKeys.Names]">
        </valben-dictionary-input>
        <valben-dictionary-input
            [textArea]="true"
            [key]="createBenefitKeys.Descriptions"
            [itemObservable]="addItemObservable"
            [update]="updateAddDescriptions"
            [currentItem]="currentAddItem"
            title="Opis"
            [error]="cErrors[createBenefitKeys.Descriptions]">
        </valben-dictionary-input>
        <valben-boolean-input
            [key]="createBenefitKeys.IsActive"
            [itemObservable]="addItemObservable"
            [update]="updateAddIsActive"
            title="Aktivan"
            [optional]="true"
            [error]="cErrors[createBenefitKeys.IsActive]">
        </valben-boolean-input>
        <valben-file-input
            [key]="createBenefitKeys.Image"
            [itemObservable]="addItemObservable"
            [update]="updateAddImage"
            title="Prenesi sliku ili direktni url postojeće slike"
            fileType="image/*"
            [error]="cErrors[createBenefitKeys.Image]">
        </valben-file-input>
        <valben-dependencies
            title="Filteri"
            [itemObservable]="addItemObservable"
            [key]="createBenefitKeys.Dependencies"
            [update]="updateAddDependencies"
            [error]="cErrors[createBenefitKeys.Dependencies]">
        </valben-dependencies>
        <div class="flex gap-4 justify-end items-center select-none">
            <button class="text-lg valben-button" (click)="draft()">Skica</button>
            <button class="text-lg valben-button" (click)="save()">Spremi</button>
        </div>
    </div>

    <div
        *ngSwitchCase="2"
        class="flex flex-col gap-4 bg-valamargray border-2 static p-4"
        [ngClass]="(loading$ | async) ? 'blur-[2px] pointer-events-none [&_*]:select-none' : ''"
    >
        <button class="flex valben-button text-lg ml-auto" (click)="modify()">Spremi</button>
        <valben-dictionary-input
            [textArea]="false"
            [key]="modifyBenefitKeys.Names"
            [itemObservable]="editItemObservable"
            [update]="updateEditNames"
            [currentItem]="currentEditItem"
            title="Naziv"
            [error]="mErrors[modifyBenefitKeys.Names]">
        </valben-dictionary-input>
        <valben-dictionary-input
            [textArea]="true"
            [key]="modifyBenefitKeys.Descriptions"
            [itemObservable]="editItemObservable"
            [update]="updateEditDescriptions"
            [currentItem]="currentEditItem"
            title="Opis"
            [error]="mErrors[modifyBenefitKeys.Descriptions]">
        </valben-dictionary-input>
        <valben-boolean-input
            [key]="modifyBenefitKeys.IsActive"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsActive"
            title="Aktivan"
            [optional]="true"
            [error]="mErrors[modifyBenefitKeys.IsActive]">
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifyBenefitKeys.IsDraft"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDraft"
            title="Skica"
            [optional]="true"
            [error]="mErrors[modifyBenefitKeys.IsDraft]">
        </valben-boolean-input>
        <valben-boolean-input
            [key]="modifyBenefitKeys.IsDeleted"
            [itemObservable]="editItemObservable"
            [update]="updateEditIsDeleted"
            title="Obriši"
            [optional]="true"
            [error]="mErrors[modifyBenefitKeys.IsDeleted]">
        </valben-boolean-input>
        <valben-file-input
            [key]="modifyBenefitKeys.Image"
            [itemObservable]="editItemObservable"
            [update]="updateEditImage"
            title="Prenesi sliku ili direktni url postojeće slike"
            fileType="image/*"
            [error]="mErrors[createBenefitKeys.Image]">
        </valben-file-input>
        <valben-dependencies
            title="Filteri"
            [itemObservable]="editItemObservable"
            [key]="modifyBenefitKeys.Dependencies"
            [update]="updateEditDependencies"
            [error]="mErrors[modifyBenefitKeys.Dependencies]">
        </valben-dependencies>
        <button class="valben-button font-semibold text-lg flex ml-auto mr-7 my-5" (click)="modify()">SPREMI</button>
    </div>
</div>
