import { Component, OnInit, Input } from "@angular/core"
import { Observable } from "rxjs"

@Component({
    selector: "valben-type-input",
    templateUrl: "./type-input.component.html",
})
export class TypeInputComponent implements OnInit {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() error: boolean = false

    options: { display: string; value: string }[] = [
        { value: "Award", display: "Nagrada" },
        { value: "Perk", display: "Pogodnost" },
    ]

    selectedOption!: { value: string; display: string }

    onChange(): void {
        this.update(this.selectedOption.value)
    }

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value != null) {
                this.selectedOption = this.options.find(
                    option => option.value.toLowerCase() === value[this.key].toLowerCase()
                ) as {
                    value: string
                    display: string
                }
            }
        })
    }
}
