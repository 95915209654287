import { Component, Input } from "@angular/core"
import { Observable } from "rxjs"
import { ContextService } from "src/app/services/context.service"

@Component({
    selector: "valben-cities-multiple-choice",
    templateUrl: "./cities-multiple-choice.component.html",
})
export class CitiesMultipleChoiceComponent {
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() key!: string
    @Input() title: string = ""
    @Input() error: boolean = false

    // todo
    dataObservable: () => Observable<any> | undefined = () => undefined

    constructor(private contextService: ContextService) {}
}
