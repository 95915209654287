import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable, map } from "rxjs"
import { Counter } from "../models"
import { environment } from "src/environments/environment"
import {
    AWARDS_KEY,
    BENEFITS_KEY,
    CATEGORIES_KEY,
    CITIES_KEY,
    NEWS_KEY,
    PERKS_KEY,
    SURVEYS_KEY,
    PROM_CONTENT_KEY,
} from "../models"

@Injectable({
    providedIn: "root",
})
export class LoadingService {
    private _counters: Map<string, Counter> = new Map<string, Counter>()

    constructor() {
        this.init()
    }

    private init(): void {
        const awardsBs = new BehaviorSubject<number>(0)
        const benefitsBs = new BehaviorSubject<number>(0)
        const categoriesBs = new BehaviorSubject<number>(0)
        const citiesBs = new BehaviorSubject<number>(0)
        const newsBs = new BehaviorSubject<number>(0)
        const perksBs = new BehaviorSubject<number>(0)
        const surveysBs = new BehaviorSubject<number>(0)
        const promContentBs = new BehaviorSubject<number>(0)

        const awardsCounter: Counter = {
            count: awardsBs,
            dependencies: [
                environment.endpointRoutes.awards,
                environment.endpointRoutes.categories,
                environment.endpointRoutes.history,
                environment.endpointRoutes.dependencies,
                environment.endpointRoutes.employee,
            ],
        }
        const benefitsCounter: Counter = {
            count: benefitsBs,
            dependencies: [
                environment.endpointRoutes.benefits,
                environment.endpointRoutes.history,
                environment.endpointRoutes.dependencies,
                environment.endpointRoutes.employee,
            ],
        }
        const categoriesCounter: Counter = {
            count: categoriesBs,
            dependencies: [environment.endpointRoutes.categories, environment.endpointRoutes.history],
        }
        const citiesCounter: Counter = {
            count: citiesBs,
            dependencies: [environment.endpointRoutes.cities, environment.endpointRoutes.history],
        }
        const newsCounter: Counter = {
            count: newsBs,
            dependencies: [environment.endpointRoutes.news, environment.endpointRoutes.history],
        }
        const perksCounter: Counter = {
            count: perksBs,
            dependencies: [
                environment.endpointRoutes.perks,
                environment.endpointRoutes.history,
                environment.endpointRoutes.dependencies,
                environment.endpointRoutes.categories,
            ],
        }
        const surveysCounter: Counter = {
            count: surveysBs,
            dependencies: [environment.endpointRoutes.surveys, environment.endpointRoutes.history],
        }
        const promContentCounter: Counter = {
            count: promContentBs,
            dependencies: [environment.endpointRoutes.promotionalContent, environment.endpointRoutes.history],
        }

        this._counters.set(AWARDS_KEY, awardsCounter)
        this._counters.set(BENEFITS_KEY, benefitsCounter)
        this._counters.set(CATEGORIES_KEY, categoriesCounter)
        this._counters.set(CITIES_KEY, citiesCounter)
        this._counters.set(NEWS_KEY, newsCounter)
        this._counters.set(PERKS_KEY, perksCounter)
        this._counters.set(SURVEYS_KEY, surveysCounter)
        this._counters.set(PROM_CONTENT_KEY, promContentCounter)
    }

    awardsLoading(): Observable<boolean> {
        return this._counters
            .get(AWARDS_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    benefitsLoading(): Observable<boolean> {
        return this._counters
            .get(BENEFITS_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    categoriesLoading(): Observable<boolean> {
        return this._counters
            .get(CATEGORIES_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    citiesLoading(): Observable<boolean> {
        return this._counters
            .get(CITIES_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    newsLoading(): Observable<boolean> {
        return this._counters
            .get(NEWS_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    perksLoading(): Observable<boolean> {
        return this._counters
            .get(PERKS_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    surveysLoading(): Observable<boolean> {
        return this._counters
            .get(SURVEYS_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    promContentLoading(): Observable<boolean>  {
        return this._counters
            .get(PROM_CONTENT_KEY)!
            .count.asObservable()
            .pipe(map(count => count > 0))
    }

    increment(key: string): void {
        for (const counter of this._counters.values()) {
            if (counter.dependencies.some(d => key.startsWith(d))) {
                counter.count.next(counter.count.value + 1)
            }
        }
    }

    decrement(key: string): void {
        for (const counter of this._counters.values()) {
            if (counter.dependencies.some(d => key.startsWith(d))) {
                counter.count.next(counter.count.value - 1)
            }
        }
    }
}
