import { AfterViewInit, Component, Input, OnInit } from "@angular/core"
import { Observable } from "rxjs"
import * as L from "leaflet"

@Component({
    selector: "valben-location-input",
    templateUrl: "./location-input.component.html",
    styles: [
        `
            .map-container {
                position: relative;
                min-width: 500px;
                width: 35%;
                height: 500px;
            }

            .map-frame {
                height: 100%;
            }

            #map {
                height: 100%;
            }
        `,
    ],
})
export class LocationInputComponent implements OnInit, AfterViewInit {
    @Input() key: string = ""
    @Input() itemObservable!: () => Observable<any> | undefined
    @Input() update!: (value: any) => void
    @Input() title: string = ""
    @Input() error: boolean = false

    private map!: L.Map
    private marker!: L.Marker
    private latlng: { lat: number; lng: number } | null = null

    private initMap(): void {
        this.map = L.map("map", {
            center: [45.2033, 13.9114],
            zoom: 7,
        })

        const tiles = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 18,
            minZoom: 8,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        })

        this.map.on("click", (e: L.LeafletMouseEvent) => {
            this.update({ lat: e.latlng.lat, lng: e.latlng.lng })
        })

        tiles.addTo(this.map)
    }

    private setMarker(latlng: L.LatLng): void {
        if (this.marker) {
            this.map.removeLayer(this.marker)
        }

        this.marker = L.marker(latlng).addTo(this.map)
    }

    ngOnInit(): void {
        this.itemObservable()?.subscribe((value: any) => {
            if (value !== null) {
                if (
                    value[this.key].lat !== this.latlng?.lat ||
                    value[this.key].lng !== this.latlng?.lng ||
                    this.latlng === null
                ) {
                    this.latlng = value[this.key]
                    const ll = L.latLng(this.latlng!.lat, this.latlng!.lng)
                    this.setMarker(ll)
                }
            }
        })
    }

    ngAfterViewInit(): void {
        this.initMap()
    }
}
