export enum CategoryTypes {
    Award = "Award",
    Perk = "Perk",
}

export interface Category {
    masterId: string
    createdByUser: string
    modifiedByUser: string
    createdDate: Date
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    version: number
    level: number
    names: {
        [key: string]: string
    }
    parentId: string
    type: string
}
