import { BehaviorSubject } from "rxjs"
import {
    IResult,
    Award,
    Category,
    Benefit,
    CreateCategory,
    CreateCategoryFactory,
    ModifyCategory,
    ModifyCategoryFactory,
    CreateAward,
    CreateAwardFactory,
    CreateBenefit,
    CreateBenefitFactory,
    ModifyBenefit,
    ModifyBenefitFactory,
    Perk,
    CreatePerk,
    CreatePerkFactory,
    ModifyAward,
    ModifyAwardFactory,
    ModifyPerk,
    ModifyPerkFactory,
    City,
    CreateCity,
    CreateCityFactory,
    ModifyCity,
    ModifyCityFactory,
    Survey,
    CreateSurvey,
    CreateSurveyFactory,
    ModifySurvey,
    ModifySurveyFactory,
    News,
    CreateNews,
    CreateNewsFactory,
    ModifyNews,
    ModifyNewsFactory,
    PromotionalContent,
    CreatePromotionalContent,
    ModifyPromotionalContent,
    CreatePromotionalContentFactory,
    ModifyPromotionalContentFactory,
} from ".."

export interface ISubjects {
    items: BehaviorSubject<any[]>
    currentAddItem: BehaviorSubject<any>
    currentEditItem: BehaviorSubject<any>
    result: BehaviorSubject<IResult | null>
}

export interface IContextState {
    subjects: ISubjects
    currentEditedMasterId: string | undefined
    resetAddItem: () => void
    resetEditItem: (masterId: string | undefined) => void
    getKey: () => string
    setFieldAdd: (key: string, value: any) => void
    setFieldEdit: (key: string, value: any) => void
    sendResult: (result: IResult) => void
}

export function setCurrentEditedMasterId(ct: IContextState, masterId: string | undefined) {
    ct.currentEditedMasterId = masterId
}

function createContextState(
    items: BehaviorSubject<any[]>,
    currentAddItem: BehaviorSubject<any>,
    currentEditItem: BehaviorSubject<any>,
    result: BehaviorSubject<IResult | null>,
    resetAddItem: () => void,
    resetEditItem: (masterId: string | undefined) => void,
    getKey: () => string,
    setFieldAdd: (key: string, value: any) => void,
    setFieldEdit: (key: string, value: any) => void,
    sendResult: (result: IResult) => void
): IContextState {
    return {
        subjects: {
            items,
            currentAddItem,
            currentEditItem,
            result,
        },
        currentEditedMasterId: "",
        resetAddItem,
        resetEditItem,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult,
    }
}

export function createCategoryContextState(key: string): IContextState {
    const categoriesBS: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>([])
    const currentAddCategoryBS: BehaviorSubject<CreateCategory> = new BehaviorSubject<CreateCategory>(
        CreateCategoryFactory.create()
    )
    const currentEditCategoryBS: BehaviorSubject<ModifyCategory | null> = new BehaviorSubject<ModifyCategory | null>(
        null
    )
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)
    const resetAddCategory = () => {
        currentAddCategoryBS.next(CreateCategoryFactory.create())
    }
    const resetEditCategory = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditCategoryBS.next(null)
        } else {
            const cat = categoriesBS.value.find(c => c.masterId === masterId)
            if (cat === undefined) {
                currentEditCategoryBS.next(null)
                return
            }
            currentEditCategoryBS.next(ModifyCategoryFactory.from(cat))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddCategoryBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddCategoryBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditCategoryBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditCategoryBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        categoriesBS,
        currentAddCategoryBS,
        currentEditCategoryBS,
        resultBS,
        resetAddCategory,
        resetEditCategory,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}

export function createAwardsContextState(key: string): IContextState {
    const awardsBS: BehaviorSubject<Award[]> = new BehaviorSubject<Award[]>([])
    const currentAddAwardBS: BehaviorSubject<CreateAward> = new BehaviorSubject<CreateAward>(
        CreateAwardFactory.create()
    )
    const currentEditAwardBS: BehaviorSubject<ModifyAward | null> = new BehaviorSubject<ModifyAward | null>(null)
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)

    const resetAddAward = () => {
        currentAddAwardBS.next(CreateAwardFactory.create())
    }

    const resetEditAward = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditAwardBS.next(null)
        } else {
            const award = awardsBS.value.find(a => a.masterId === masterId)
            if (award === undefined) {
                currentEditAwardBS.next(null)
                return
            }
            currentEditAwardBS.next(ModifyAwardFactory.from(award))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddAwardBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddAwardBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditAwardBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditAwardBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        awardsBS,
        currentAddAwardBS,
        currentEditAwardBS,
        resultBS,
        resetAddAward,
        resetEditAward,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}

export function createBenefitContextState(key: string): IContextState {
    const benefitsBS: BehaviorSubject<Benefit[]> = new BehaviorSubject<Benefit[]>([])
    const currentAddBenefitBS: BehaviorSubject<CreateBenefit> = new BehaviorSubject<CreateBenefit>(
        CreateBenefitFactory.create()
    )
    const currentEditBenefitBS: BehaviorSubject<ModifyBenefit | null> = new BehaviorSubject<ModifyBenefit | null>(null)
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)
    const resetAddBenefit = () => {
        currentAddBenefitBS.next(CreateBenefitFactory.create())
    }
    const resetEditBenefit = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditBenefitBS.next(null)
        } else {
            const ben = benefitsBS.value.find(b => b.masterId === masterId)
            if (ben === undefined) {
                currentEditBenefitBS.next(null)
                return
            }
            currentEditBenefitBS.next(ModifyBenefitFactory.from(ben))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddBenefitBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddBenefitBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditBenefitBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditBenefitBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        benefitsBS,
        currentAddBenefitBS,
        currentEditBenefitBS,
        resultBS,
        resetAddBenefit,
        resetEditBenefit,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}

export function createPerksContextState(key: string): IContextState {
    const perksBS: BehaviorSubject<Perk[]> = new BehaviorSubject<Perk[]>([])
    const currentAddPerkBS: BehaviorSubject<CreatePerk> = new BehaviorSubject<CreatePerk>(CreatePerkFactory.create())
    const currentEditPerkBS: BehaviorSubject<ModifyPerk | null> = new BehaviorSubject<ModifyPerk | null>(null)
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)

    const resetAddPerk = () => {
        currentAddPerkBS.next(CreatePerkFactory.create())
    }

    const resetEditPerk = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditPerkBS.next(null)
        } else {
            const perk = perksBS.value.find(p => p.masterId === masterId)
            if (perk === undefined) {
                currentEditPerkBS.next(null)
                return
            }
            currentEditPerkBS.next(ModifyPerkFactory.from(perk))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddPerkBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddPerkBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditPerkBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditPerkBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        perksBS,
        currentAddPerkBS,
        currentEditPerkBS,
        resultBS,
        resetAddPerk,
        resetEditPerk,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}

export function createSurveysContextState(key: string): IContextState {
    const surveysBS: BehaviorSubject<Survey[]> = new BehaviorSubject<Survey[]>([])
    const currentAddSurveyBS: BehaviorSubject<CreateSurvey> = new BehaviorSubject<CreateSurvey>(
        CreateSurveyFactory.create()
    )
    const currentEditSurveyBS: BehaviorSubject<ModifySurvey | null> = new BehaviorSubject<ModifySurvey | null>(null)
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)
    const resetAddSurvey = () => {
        currentAddSurveyBS.next(CreateSurveyFactory.create())
    }
    const resetEditSurvey = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditSurveyBS.next(null)
        } else {
            const city = surveysBS.value.find(c => c.masterId === masterId)
            if (city === undefined) {
                currentEditSurveyBS.next(null)
                return
            }
            currentEditSurveyBS.next(ModifySurveyFactory.from(city))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddSurveyBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddSurveyBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditSurveyBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditSurveyBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        surveysBS,
        currentAddSurveyBS,
        currentEditSurveyBS,
        resultBS,
        resetAddSurvey,
        resetEditSurvey,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}

export function createCitiesContextState(key: string): IContextState {
    const citiesBS: BehaviorSubject<City[]> = new BehaviorSubject<City[]>([])
    const currentAddCityBS: BehaviorSubject<CreateCity> = new BehaviorSubject<CreateCity>(CreateCityFactory.create())
    const currentEditCityBS: BehaviorSubject<ModifyCity | null> = new BehaviorSubject<ModifyCity | null>(null)
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)
    const resetAddCity = () => {
        currentAddCityBS.next(CreateCityFactory.create())
    }
    const resetEditCity = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditCityBS.next(null)
        } else {
            const city = citiesBS.value.find(c => c.masterId === masterId)
            if (city === undefined) {
                currentEditCityBS.next(null)
                return
            }
            currentEditCityBS.next(ModifyCityFactory.from(city))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddCityBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddCityBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditCityBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditCityBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        citiesBS,
        currentAddCityBS,
        currentEditCityBS,
        resultBS,
        resetAddCity,
        resetEditCity,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}

export function createNewsContextState(key: string): IContextState {
    const newsBS: BehaviorSubject<News[]> = new BehaviorSubject<News[]>([])
    const currentAddNewsBS: BehaviorSubject<CreateNews> = new BehaviorSubject<CreateNews>(CreateNewsFactory.create())
    const currentEditNewsBS: BehaviorSubject<ModifyNews | null> = new BehaviorSubject<ModifyNews | null>(null)
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)

    const resetAddNews = () => {
        currentAddNewsBS.next(CreateNewsFactory.create())
    }

    const resetEditNews = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditNewsBS.next(null)
        } else {
            const news = newsBS.value.find(n => n.masterId === masterId)
            if (news === undefined) {
                currentEditNewsBS.next(null)
                return
            }
            currentEditNewsBS.next(ModifyNewsFactory.from(news))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddNewsBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddNewsBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditNewsBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditNewsBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        newsBS,
        currentAddNewsBS,
        currentEditNewsBS,
        resultBS,
        resetAddNews,
        resetEditNews,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}

export function createPromContentContextState(key: string): IContextState {
    const promContentBS: BehaviorSubject<PromotionalContent[]> = new BehaviorSubject<PromotionalContent[]>([])
    const currentAddPromContentBS: BehaviorSubject<CreatePromotionalContent> = new BehaviorSubject<CreatePromotionalContent>(CreatePromotionalContentFactory.create())
    const currentEditPromContentBS: BehaviorSubject<ModifyPromotionalContent | null> = new BehaviorSubject<ModifyPromotionalContent | null>(null)
    const resultBS: BehaviorSubject<IResult | null> = new BehaviorSubject<IResult | null>(null)

    const resetAddPromContent = () => {
        currentAddPromContentBS.next(CreatePromotionalContentFactory.create())
    }

    const resetEditPromContent = (masterId: string | undefined) => {
        if (masterId === undefined) {
            currentEditPromContentBS.next(null)
        } else {
            const promContent = promContentBS.value.find(n => n.masterId === masterId)
            if (promContent === undefined) {
                currentEditPromContentBS.next(null)
                return
            }
            currentEditPromContentBS.next(ModifyPromotionalContentFactory.from(promContent))
        }
    }

    const getKey = () => {
        return key
    }

    const setFieldAdd = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentAddPromContentBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentAddPromContentBS.next(currentCopy)
    }

    const setFieldEdit = (key: string, value: any) => {
        const currentCopy = JSON.parse(JSON.stringify(currentEditPromContentBS.value))
        currentCopy[key] = value
        console.log(currentCopy)
        currentEditPromContentBS.next(currentCopy)
    }

    const sendResult = (result: IResult) => {
        resultBS.next(result)
        resultBS.next(null)
    }

    return createContextState(
        promContentBS,
        currentAddPromContentBS,
        currentEditPromContentBS,
        resultBS,
        resetAddPromContent,
        resetEditPromContent,
        getKey,
        setFieldAdd,
        setFieldEdit,
        sendResult
    )
}
