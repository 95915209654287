import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { environment } from "src/environments/environment"
import { City, CreateCity, ModifyObject } from "../models"

@Injectable({
    providedIn: "root",
})
export class CitiesService {
    private apiUrl = environment.apiBaseUrl + environment.endpointRoutes.cities

    constructor(private http: HttpClient) {}

    Get(): Observable<City[]> {
        return this.http.get<City[]>(this.apiUrl)
    }

    Create(createCity: CreateCity): Observable<City> {
        return this.http.post<City>(this.apiUrl, createCity)
    }

    Modify(masterId: string, data: ModifyObject): Observable<City> {
        return this.http.patch<City>(this.apiUrl + `/${masterId}`, data)
    }

    Delete(masterId: string): Observable<any> {
        return this.http.delete(this.apiUrl + `/${masterId}`)
    }
}
