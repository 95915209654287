import { PositionsRepresentation, OrgUnitsRepresentation } from "../../models"

export interface OrgUnits {
    treeRepresentation: OrgUnitsRepresentation
    orgUnitIds: string[]
}

export interface Positions {
    treeRepresentation: PositionsRepresentation
    positions: string[]
}

export interface CostCenters {
    costCenters: string[]
    types: string[]
}

export interface FilterGroup {
    orgUnits: OrgUnits | null
    positions: Positions | null
    costCenters: CostCenters | null
}

export interface Dependencies {
    filterGroups: FilterGroup[]
    includedEmployees: string[] | null
    excludedEmployees: string[] | null
}

export class DependenciesFactory {
    static create(): Dependencies {
        return {
            filterGroups: [],
            includedEmployees: null,
            excludedEmployees: null,
        }
    }
}

export const createFilterGroup = (): FilterGroup => {
    return {
        orgUnits: null,
        positions: null,
        costCenters: null,
    }
}
