<div
    class="flex px-10 py-4 border-2 rounded-md items-center gap-2"
    [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
    <div class="text-lg font-semibold my-1">
        {{ title }}
        <span *ngIf="optional" class="italic text-xs font-light">neobavezno</span>
    </div>
    <div class="py-2 px-1">
        <p-checkbox [(ngModel)]="currentValue" [binary]="true" (onChange)="onChange()"></p-checkbox>
    </div>
</div>
