import { Component, OnDestroy, OnInit } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { MessageService } from "primeng/api"
import {
    Column,
    ContentType,
    CreateCity,
    CreateCityKeysObject,
    CreateEmptyCityErrorsObject,
    ModifyCity,
    ModifyCityKeysObject,
    ModifyEmptyCityErrorsObject,
    setErrors,
} from "../../models"
import { LoadingService, ContextService } from "../../services"

@Component({
    selector: "valben-cities",
    templateUrl: "./cities.page.html",
    styles: [`
        :host {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    `],
})
export class CitiesPage implements OnInit, OnDestroy {
    tableValues: any[] = []

    resultSubscription: Subscription | undefined = new Subscription()
    itemsSubscription: Subscription | undefined = new Subscription()
    addItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getAddACitySubject()?.asObservable()
    editItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getEditCitySubject()?.asObservable()

    updateAddNames: (value: any) => void
    updateAddIsActive: (value: any) => void
    updateEditNames: (value: any) => void
    updateEditIsActive: (value: any) => void
    updateEditIsDraft: (value: any) => void
    updateEditIsDeleted: (value: any) => void
    createCityKeys = CreateCityKeysObject()
    modifyCityKeys = ModifyCityKeysObject()
    currentAddItem: () => CreateCity
    currentEditItem: () => ModifyCity

    addFormType: string = ""
    editFormType: string = ""
    cityKey: string = ""

    loading$ = this.loadingService.citiesLoading()
    cErrors: { [key: string]: boolean } = CreateEmptyCityErrorsObject()
    mErrors: { [key: string]: boolean } = ModifyEmptyCityErrorsObject()
    refresh = () => this.contextService.resetCities()

    cols: Column[] = [
        { field: "names", header: "Naslov", filterType: "text", default: "hr", bodyType: "langs", filterable: true },
        { field: "createdByUser", header: "Kreiranje", filterType: "text", bodyType: "string" },
        { field: "modifiedByUser", header: "Zadnja izmjena", filterType: "text", bodyType: "string" },
        { field: "createdDate", header: "Datum kreiranja", filterType: "date", bodyType: "date" },
        { field: "isDraft", header: "Skica", filterType: "boolean", bodyType: "boolean" },
        { field: "isActive", header: "Aktivan", filterType: "boolean", bodyType: "boolean" },
        { field: "version", header: "Verzija", filterType: "numeric", bodyType: "number" },
    ]

    contentType: ContentType = ContentType.SEARCH

    editEnabled = false

    setContentType(value: string) {
        switch (value) {
            case "Search":
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
            case "Add":
                if (!(this.contentType === ContentType.ADD)) {
                    this.contentType = ContentType.ADD
                }
                break
            case "Edit":
                if (!(this.contentType === ContentType.EDIT)) {
                    this.contentType = ContentType.EDIT
                }
                break
            default:
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
        }
    }

    constructor(
        private messageService: MessageService,
        private contextService: ContextService,
        private loadingService: LoadingService
    ) {
        this.addFormType = this.contextService.getAddKey()
        this.editFormType = this.contextService.getEditKey()
        this.cityKey = this.contextService.getCitiesKey()
        this.updateAddNames = (value: any) => {
            this.contextService.setItem(this.cityKey, this.createCityKeys.Names, value, this.addFormType)
        }
        this.updateAddIsActive = (value: any) => {
            this.contextService.setItem(this.cityKey, this.createCityKeys.IsActive, value, this.addFormType)
        }
        this.updateEditNames = (value: any) => {
            this.contextService.setItem(this.cityKey, this.modifyCityKeys.Names, value, this.editFormType)
        }
        this.updateEditIsActive = (value: any) => {
            this.contextService.setItem(this.cityKey, this.modifyCityKeys.IsActive, value, this.editFormType)
        }
        this.updateEditIsDraft = (value: any) => {
            this.contextService.setItem(this.cityKey, this.modifyCityKeys.IsDraft, value, this.editFormType)
        }
        this.updateEditIsDeleted = (value: any) => {
            this.contextService.setItem(this.cityKey, this.modifyCityKeys.IsDeleted, value, this.editFormType)
        }
        this.currentAddItem = () => this.contextService.getAddACitySubject()?.value
        this.currentEditItem = () => this.contextService.getEditCitySubject()?.value
    }

    ngOnInit(): void {
        this.itemsSubscription = this.contextService.getCitiesSubject()?.subscribe(res => {
            this.tableValues = JSON.parse(JSON.stringify(res))
            this.tableValues.forEach(x => {
                return (x.createdDate = new Date(x.createdDate))
            })
        })
        this.resultSubscription = this.contextService.getCitiesResultSubject()?.subscribe(res => {
            if (res !== null) {
                if (res.formType === this.editFormType && res.success) {
                    this.editEnabled = false
                    this.setContentType("Search")
                }
                res.formType === this.editFormType
                    ? setErrors(res.errorFields, this.mErrors)
                    : setErrors(res.errorFields, this.cErrors)
                this.messageService.add({
                    severity: res.error ? "error" : "success",
                    summary: res.error ? "Error" : "Success",
                    detail: res.message,
                })
            }
        })
    }

    ngOnDestroy(): void {
        this.tableValues = []
        this.itemsSubscription?.unsubscribe()
        this.resultSubscription?.unsubscribe()
    }

    editOnClick(masterId: string) {
        this.contextService.setCurrentEditCity(masterId)
        this.editEnabled = true
        this.setContentType("Edit")
    }

    save() {
        this.contextService.saveCity()
    }

    draft() {
        this.contextService.draftCity()
    }

    modify() {
        this.contextService.modifyCity()
    }
}
