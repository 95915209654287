import { Component, OnInit, OnDestroy } from "@angular/core"
import { Observable, Subscription } from "rxjs"
import { MessageService } from "primeng/api"
import {
    Column,
    ContentType,
    CreateBenefit,
    CreateBenefitKeysObject,
    CreateEmptyBenefitErrorsObject,
    Dependencies,
    FileContent,
    ModifyBenefit,
    ModifyBenefitKeysObject,
    ModifyEmptyBenefitErrorsObject,
    setErrors,
} from "../../models"
import { LoadingService, ContextService } from "../../services"

@Component({
    selector: "valben-benefits",
    templateUrl: "./benefits.page.html",
    styles: [`
        :host {
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    `],
})
export class BenefitsPage implements OnInit, OnDestroy {
    tableValues: any[] = []

    resultSubscription: Subscription | undefined = new Subscription()
    itemsSubscription: Subscription | undefined = new Subscription()
    addItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getAddBenefitSubject()?.asObservable()
    editItemObservable: () => Observable<any> | undefined = () =>
        this.contextService.getEditBenefitSubject()?.asObservable()

    updateAddIsActive: (value: any) => void
    updateAddNames: (value: any) => void
    updateAddDescriptions: (value: any) => void
    updateAddImage: (value: FileContent) => void
    updateAddDependencies: (value: Dependencies) => void

    updateEditIsActive: (value: any) => void
    updateEditIsDraft: (value: any) => void
    updateEditIsDeleted: (value: any) => void
    updateEditNames: (value: any) => void
    updateEditDescriptions: (value: any) => void
    updateEditImage: (value: FileContent) => void
    updateEditDependencies: (value: Dependencies) => void

    createBenefitKeys = CreateBenefitKeysObject()
    modifyBenefitKeys = ModifyBenefitKeysObject()
    currentAddItem: () => CreateBenefit
    currentEditItem: () => ModifyBenefit

    addFormType: string = ""
    editFormType: string = ""
    benefitKey: string = ""

    currentEditedBenefit: () => string | undefined = () => this.contextService.currentEditedBenefit()

    loading$ = this.loadingService.benefitsLoading()
    cErrors: { [key: string]: boolean } = CreateEmptyBenefitErrorsObject()
    mErrors: { [key: string]: boolean } = ModifyEmptyBenefitErrorsObject()
    refresh = () => this.contextService.resetBenefits()

    cols: Column[] = [
        { field: "names", header: "Naslov", filterType: "text", default: "hr", bodyType: "langs", filterable: true },
        {
            field: "descriptions",
            header: "Opis",
            filterType: "text",
            default: "hr",
            bodyType: "langs",
            filterable: true,
        },
        { field: "createdByUser", header: "Kreiranje", filterType: "text", bodyType: "string" },
        { field: "modifiedByUser", header: "Zadnja izmjena", filterType: "text", bodyType: "string" },
        { field: "createdDate", header: "Datum kreiranja", filterType: "date", bodyType: "date" },
        { field: "isDraft", header: "Skica", filterType: "boolean", bodyType: "boolean" },
        { field: "isActive", header: "Aktivan", filterType: "boolean", bodyType: "boolean" },
        { field: "version", header: "Verzija", filterType: "numeric", bodyType: "number" },
        { field: "imageUrl", header: "Slika", bodyType: "file", sortable: false, filterable: false },
    ]
    contentType: ContentType = ContentType.SEARCH

    editEnabled = false

    setContentType(value: string) {
        switch (value) {
            case "Search":
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
            case "Add":
                if (!(this.contentType === ContentType.ADD)) {
                    this.contentType = ContentType.ADD
                }
                break
            case "Edit":
                if (!(this.contentType === ContentType.EDIT)) {
                    this.contentType = ContentType.EDIT
                }
                break
            default:
                if (!(this.contentType === ContentType.SEARCH)) {
                    this.contentType = ContentType.SEARCH
                }
                break
        }
    }

    constructor(
        private messageService: MessageService,
        private contextService: ContextService,
        private loadingService: LoadingService,
    ) {
        this.addFormType = this.contextService.getAddKey()
        this.editFormType = this.contextService.getEditKey()
        this.benefitKey = this.contextService.getBenefitsKey()

        this.updateAddIsActive = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.createBenefitKeys.IsActive, value, this.addFormType)
        }
        this.updateAddNames = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.createBenefitKeys.Names, value, this.addFormType)
        }
        this.updateAddDescriptions = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.createBenefitKeys.Descriptions, value, this.addFormType)
        }
        this.updateAddImage = (value: FileContent) => {
            this.contextService.setItem(this.benefitKey, this.createBenefitKeys.Image, value, this.addFormType)
        }
        this.updateAddDependencies = (value: Dependencies) => {
            this.contextService.setItem(this.benefitKey, this.createBenefitKeys.Dependencies, value, this.addFormType)
        }

        this.updateEditIsActive = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.modifyBenefitKeys.IsActive, value, this.editFormType)
        }
        this.updateEditIsDraft = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.modifyBenefitKeys.IsDraft, value, this.editFormType)
        }
        this.updateEditIsDeleted = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.modifyBenefitKeys.IsDeleted, value, this.editFormType)
        }
        this.updateEditNames = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.modifyBenefitKeys.Names, value, this.editFormType)
        }
        this.updateEditDescriptions = (value: any) => {
            this.contextService.setItem(this.benefitKey, this.modifyBenefitKeys.Descriptions, value, this.editFormType)
        }
        this.updateEditImage = (value: FileContent) => {
            this.contextService.setItem(this.benefitKey, this.modifyBenefitKeys.Image, value, this.editFormType)
        }
        this.updateEditDependencies = (value: Dependencies) => {
            this.contextService.setItem(this.benefitKey, this.modifyBenefitKeys.Dependencies, value, this.editFormType)
        }

        this.currentAddItem = () => this.contextService.getAddBenefitSubject()?.value
        this.currentEditItem = () => this.contextService.getEditBenefitSubject()?.value
    }

    ngOnInit(): void {
        this.itemsSubscription = this.contextService.getBenefitsSubject()?.subscribe(res => {
            this.tableValues = JSON.parse(JSON.stringify(res))
            this.tableValues.forEach(x => {
                return (x.createdDate = new Date(x.createdDate))
            })
        })
        this.resultSubscription = this.contextService.getBenefitResultSubject()?.subscribe(res => {
            if (res !== null) {
                if (res.formType === this.editFormType && res.success) {
                    this.editEnabled = false
                    this.setContentType("Search")
                }
                res.formType === this.editFormType
                    ? setErrors(res.errorFields, this.mErrors)
                    : setErrors(res.errorFields, this.cErrors)
                this.messageService.add({
                    severity: res.error ? "error" : "success",
                    summary: res.error ? "Error" : "Success",
                    detail: res.message,
                })
            }
        })
    }

    ngOnDestroy(): void {
        this.tableValues = []
        this.itemsSubscription?.unsubscribe()
        this.resultSubscription?.unsubscribe()
    }

    editOnClick(masterId: string) {
        this.contextService.setCurrentEditBenefit(masterId)
        this.editEnabled = true
        this.setContentType("Edit")
    }

    save() {
        this.contextService.saveBenefit()
    }

    draft() {
        this.contextService.draftBenefit()
    }

    modify() {
        this.contextService.modifyBenefit()
    }
}
