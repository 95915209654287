import { HttpClient } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { MsalService } from "@azure/msal-angular"
import { environment } from "src/environments/environment"

@Component({
    selector: "valben-navigation",
    templateUrl: "./navigation.page.html",
})
export class NavigationPage implements OnInit {
    user: string = ""
    currPage: string = ""
    pages = [
        { name: "Novosti", url: "news" },
        { name: "Nagrade", url: "awards" },
        { name: "Benefiti", url: "benefits" },
        { name: "Pogodnosti", url: "perks" },
        { name: "Gradovi", url: "cities" },
        { name: "Kategorije", url: "categories" },
        { name: "Ankete", url: "surveys" },
        { name: "Promotivni sadržaj", url: "promotional-content" },
    ]

    constructor(
        private _ms: MsalService,
        private _http: HttpClient,
        private _router: Router,
    ) {}

    logout() {
        this._ms.logoutRedirect({
            postLogoutRedirectUri: environment.appBaseUrl,
        })
    }

    navigateTo(url: string) {
        this.currPage = url
        this._router.navigate([url])
    }

    ngOnInit(): void {
        this.currPage = this._router.url.slice(1)
        this._http.get(environment.graphEndpoint).subscribe(profile => {
            this.user = (profile as { userPrincipalName?: string }).userPrincipalName || ""
        })
    }
}

