export interface CreateCity {
    isDraft: boolean
    isActive: boolean
    names: {
        [key: string]: string
    }
}

export class CreateCityFactory {
    static create(): CreateCity {
        return {
            isDraft: false,
            isActive: false,
            names: {
                hr: "",
                en: "",
            },
        }
    }
}

enum CreateCityKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Names = "names",
}

export function CreateCityKeysObject() {
    return {
        IsDraft: CreateCityKeys.IsDraft,
        IsActive: CreateCityKeys.IsActive,
        Names: CreateCityKeys.Names,
    }
}

export function CreateEmptyCityErrorsObject(): { [key: string]: boolean } {
    return {
        [CreateCityKeys.IsActive]: false,
        [CreateCityKeys.Names]: false,
    }
}
