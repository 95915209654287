<div
    class="flex flex-col px-10 py-4 border-2 border-gray-900/10 rounded-md"
    [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
    <div class="text-lg font-semibold my-1">{{ title }}</div>
    <div class="w-1/5">
        <input
            class="w-full h-10 border focus:outline-none rounded-md text-md p-2"
            type="text"
            (input)="onChange()"
            (keyup)="onChange()"
            [(ngModel)]="currentValue"
        />
    </div>
    <div *ngIf="isNumberOrEmpty" class="py-2 px-1 text-red-500 font-semibold">*Molimo unesite samo brojeve</div>
</div>
