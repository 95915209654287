import { FileContent, FileContentFactory, Dependencies, DependenciesFactory } from ".."

export interface CreateBenefit {
    isDraft: boolean
    isActive: boolean
    names: {
        [key: string]: string
    }
    descriptions: {
        [key: string]: string
    }
    image: FileContent
    dependencies: Dependencies
}

export class CreateBenefitFactory {
    static create(): CreateBenefit {
        return {
            isDraft: false,
            isActive: false,
            names: {
                hr: "",
                en: "",
            },
            descriptions: {
                hr: "",
                en: "",
            },
            image: FileContentFactory.create(),
            dependencies: DependenciesFactory.create(),
        }
    }
}

enum CreateBenefitKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    Names = "names",
    Descriptions = "descriptions",
    Image = "image",
    Dependencies = "dependencies",
}

export function CreateBenefitKeysObject() {
    return {
        IsDraft: CreateBenefitKeys.IsDraft,
        IsActive: CreateBenefitKeys.IsActive,
        Names: CreateBenefitKeys.Names,
        Descriptions: CreateBenefitKeys.Descriptions,
        Image: CreateBenefitKeys.Image,
        Dependencies: CreateBenefitKeys.Dependencies,
    }
}

export function CreateEmptyBenefitErrorsObject(): { [key: string]: boolean } {
    return {
        [CreateBenefitKeys.IsActive]: false,
        [CreateBenefitKeys.Names]: false,
        [CreateBenefitKeys.Descriptions]: false,
        [CreateBenefitKeys.Image]: false,
        [CreateBenefitKeys.Dependencies]: false,
    }
}
