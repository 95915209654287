import { City } from ".."

export interface ModifyCity {
    isDraft: boolean
    isActive: boolean
    isDeleted: boolean
    names: {
        [key: string]: string
    }
}

export class ModifyCityFactory {
    static from(city: City): ModifyCity {
        const modifiyCity: ModifyCity = JSON.parse(
            JSON.stringify({
                isDraft: city.isDraft,
                isActive: city.isActive,
                isDeleted: city.isDeleted,
                names: city.names,
            })
        )
        return modifiyCity
    }
}

enum ModifyCityKeys {
    IsDraft = "isDraft",
    IsActive = "isActive",
    IsDeleted = "isDeleted",
    Names = "names",
}

export function ModifyCityKeysObject() {
    return {
        IsDraft: ModifyCityKeys.IsDraft,
        IsActive: ModifyCityKeys.IsActive,
        IsDeleted: ModifyCityKeys.IsDeleted,
        Names: ModifyCityKeys.Names,
    }
}

export function ModifyEmptyCityErrorsObject(): { [key: string]: boolean } {
    return {
        [ModifyCityKeys.IsActive]: false,
        [ModifyCityKeys.IsDeleted]: false,
        [ModifyCityKeys.IsDraft]: false,
        [ModifyCityKeys.Names]: false,
    }
}
