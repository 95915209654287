import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { MsalGuard } from "@azure/msal-angular"
import {
    BenefitsPage,
    NavigationPage,
    AwardsPage,
    PerksPage,
    CitiesPage,
    CategoriesPage,
    NewsPage,
    LoginPage,
    SurveysPage,
    PromotionalContentPage,
} from "./pages"

const routes: Routes = [
    {
        path: "login",
        component: LoginPage,
    },
    {
        path: "",
        redirectTo: "news",
        pathMatch: "full",
    },
    {
        path: "",
        component: NavigationPage,
        canActivate: [ MsalGuard ],
        canActivateChild: [ MsalGuard ],
        children: [
            {
                path: "news",
                component: NewsPage,
            },
            {
                path: "benefits",
                component: BenefitsPage,
            },
            {
                path: "awards",
                component: AwardsPage,
            },
            {
                path: "perks",
                component: PerksPage,
            },
            {
                path: "cities",
                component: CitiesPage,
            },
            {
                path: "categories",
                component: CategoriesPage,
            },
            {
                path: "surveys",
                component: SurveysPage,
            },
            {
                path: "promotional-content",
                component: PromotionalContentPage,
            },
        ],
    },
    {
        path: "**",
        redirectTo: "news",
        pathMatch: "full",
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
