<div
    class="flex flex-col px-10 py-4 border-2 rounded-md gap-2"
    [ngClass]="error ? 'border-red-500' : 'border-gray-900/10'"
>
    <div class="text-lg font-semibold my-1">{{ title }}</div>
    <div class="my-1">Unesite (otprilike) lokaciju na karti.</div>
    <div class="map-container">
        <div class="map-frame border-4 border-gray-900/50">
            <div id="map"></div>
        </div>
    </div>
</div>
